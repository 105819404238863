import { render, staticRenderFns } from "./todoList.vue?vue&type=template&id=74a9c2f6&scoped=true&lang=html"
import script from "./todoList.vue?vue&type=script&lang=js"
export * from "./todoList.vue?vue&type=script&lang=js"
import style0 from "./todoList.vue?vue&type=style&index=0&id=74a9c2f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a9c2f6",
  null
  
)

export default component.exports